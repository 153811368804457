<template>
    <v-card class="needMoreInfo csCard">
        <h3>{{ title }}</h3>

        <div class="needMoreInfo__container">
            <div
                v-for="(item, index) in list"
                :key="index"
                class="needMoreInfo__item"
            >
                <SVGIcon :icon-name="item.icon" class="icon__moreInfo" />

                <div class="needMoreInfo__desc">
                    <p class="needMoreInfo__title">{{ item.title }}</p>
                    <CSLink
                        :href="item.link.href"
                        :target="item.link.newWindow ? '_blank' : ''"
                        class="needMoreInfo__link"
                        :data-test="`needMoreInfo_${index}_link`"
                        :external="item.link.external"
                        @click="
                            () =>
                                trackEventForUserActions(
                                    'Need More Information',
                                    item.link.text,
                                    item.link.href,
                                )
                        "
                    >
                        {{ item.link.text }}
                    </CSLink>
                    <p v-if="item.helperText" class="needMoreInfo__helperText">
                        {{ item.helperText }}
                    </p>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import CSLink from '@/components/atoms/link/CSLink.vue'
import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'NeedMoreInfomation',

    components: {
        SVGIcon,
        CSLink,
    },

    mixins: [gtmTracking],

    props: {
        title: {
            type: String,
            default: 'Sie haben noch Fragen?',
        },
        list: {
            type: Array,
            validator: value => value.every(v => typeof v === 'object'),
            default: () => [
                {
                    title: 'Lesen Sie',
                    icon: 'conversation-question-warning',
                    helperText: '',
                    link: {
                        text: 'Häufig gestellte Fragen',
                        href: '/faq/',
                        newWindow: false,
                        external: true,
                    },
                },
                {
                    title: 'Rufen Sie uns gerne an',
                    icon: 'phone-call',
                    helperText:
                        'Mo & Mi 8-12 &15-19 Uhr sowie Do & Fr 12-15 Uhr',
                    link: {
                        text: '(030) 220 128 831',
                        href: 'tel://030220128831',
                        newWindow: false,
                        external: true,
                    },
                },
                {
                    title:
                        'Lesen Sie im Careship Magazin über aktuelle Themen ',
                    icon: 'window-search-text',
                    helperText: '',
                    link: {
                        text: 'Careship Magazin lesen',
                        href: '/senioren-ratgeber',
                        newWindow: false,
                        external: true,
                    },
                },
            ],
        },
    },
}
</script>

<style lang="stylus">
.needMoreInfo {
    margin: 0 auto;
    padding: $cs-spaces.onehalf $cs-space;
    width: 100%;

    @media $display-breakpoints.md-and-up {
        padding: $cs-spaces.onehalf;
        width: fit-content;
    }

    @media $display-breakpoints.lg-and-up {
        padding: $cs-spaces.onehalf $cs-spaces.s600;
    }

    h3 {
        margin-bottom:$cs-spaces.onehalf;
        text-align: center;
    }

    &__container {
        @media $display-breakpoints.sm-and-up {
            display: flex;
        }
    }

    &__item {
        border: solid $cs-slate-200;
        border-width: 0 0 1px;
        display: flex;
        margin: 0 0 $cs-spaces.onehalf;
        padding: 0 0 $cs-spaces.onehalf;
        width: 100%;

        @media $display-breakpoints.sm-and-up {
            border-width: 0 1px 0 0;
            box-sizing: content-box;
            flex-direction: column;
            margin: 0 $cs-spaces.onehalf 0 0;
            padding: 0 $cs-spaces.onehalf 0 0;
            width: 100%;
        }

        @media $display-breakpoints.md-and-up {
            flex-direction: row;
            margin: 0 $cs-spaces.double 0 0;
            padding: 0 $cs-spaces.double 0 0;
        }

        @media $display-breakpoints.lg-and-up {
            width: 270px;
        }

        &:last-child {
            border: 0;
            margin: 0;
            padding: 0;
        }
    }

    &__title {
        margin: 0 0 $cs-spaces.half;
    }

    &__helperText {
        color: $cs-slate-300;
        margin: $cs-spaces.half 0 0;
    }

    .icon__moreInfo {
        color: $cs-primary;
        flex: 0 0 auto;
        height: 40px;
        margin-right: $cs-spaces.onehalf;
        width: 40px;

        @media $display-breakpoints.sm-and-up {
            height: 48px;
            margin-bottom: $cs-spaces.onehalf;
            width: 48px;
        }
    }
}
</style>
